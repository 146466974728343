import urls from '@/constants/urls';

function mobileDevice() {
  if (/android/i.test(navigator.userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return 'iOS';
  };
}

function DetectAndServe() {
  let device = mobileDevice();

  if (device === 'iOS') {
    window.location.href = urls.APP_STORE;
  } else if (device === 'Android') {
    window.location.href = urls.PLAY_STORE;
  }
}

export default DetectAndServe;
